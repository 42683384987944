export const IFRAMED = !!(window.parent && window.parent.location !== window.location);

export function domain() {
  return window.CREDERE._INTERNAL?.domain;
}

export function endpoint() {
  return window.CREDERE._INTERNAL?.endpoint;
}

export default function parent() {
  if (endpoint()) {
    return window;
  }

  return IFRAMED ? window.parent : window;
}
